import { useQuery } from '@abyss/web/hooks/useQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { errorCodeRouteMap } from './../../common/Constants';
import { convertObjectToUrlParams } from '../../common/Utils';
import { PortalContext } from '../../context/PortalContext';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useErrorStore } from '../../store/useErrorStore';
import { ErrorStore } from '../../store/useErrorStore/errorStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getCurrentMember } from '../../utils/user.utils';
import { useTranslationLog } from '../useTranslationLog/useTranslationLog';
import packageJson from '../../../package.json';


const getHeaders = (currentMember, portalData, typeaheadStore) => {
  let headerOptions = {};

  // Get and set lob and population header
  let lob = typeaheadStore?.lob || currentMember?.lineOfBusiness;
  lob = lob ?? 'UNKNOWN';
  lob = lob.replace(/&/g, 'n').toLowerCase();
  const population = currentMember?.population?.toLowerCase() || lob;
  headerOptions = { lob, population };

  // Get and set portalName header
  if (portalData?.portalName) {
    headerOptions = { ...headerOptions, portal: portalData.portalName };
  }

  return headerOptions;
};

export const useCustomQuery = (query, queryOptions) => {
  const { navigate } = useRouter();
  const { portalData } = useContext(PortalContext);

  const { linkName, originLinkNameForAnalytics } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
      originLinkNameForAnalytics:
        state.analyticsState.originLinkNameForAnalytics,
    }))
  );

  const { setErrorStore } = useErrorStore(
    useShallow((state: ErrorStore) => ({
      setErrorStore: state.setErrorStore,
    }))
  );

  const { typeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
    }))
  );

  const currentMember = getCurrentMember();
  const headerOptions = getHeaders(
    currentMember,
    portalData,
    typeaheadSearchStore
  );

  // In order to get it past LAWW's proxy, the remote calls paths need to follow
  // a format. So we have a different path for LAWW.
  const url =
    portalData?.portalName !== 'LAWW'
      ? queryOptions.url
      : config('GRAPHQL_API_URL_LAWW');

  const translationLogger = useTranslationLog({}, headerOptions, url);

  // let bgTestingEnv = localStorage.getItem('bgTestingEnv') || 'live';

  // const apiGatewayUrls = {
  //   blue: 'api-gateway-psx-cdk-east-1-nonprod-blue.uhc.com',
  //   green: 'api-gateway-psx-cdk-east-1-nonprod-green.uhc.com',
  //   live: 'api-gateway-nonprod.uhc.com',
  // };

  // if (bgTestingEnv) {
  //   if (bgTestingEnv !== 'blue' && bgTestingEnv !== 'green') {
  //     localStorage.setItem('bgTestingEnv', 'live');
  //     bgTestingEnv = localStorage.getItem('bgTestingEnv') || 'live';
  //   }
  //   headerOptions['X-BG-Testing-Gateway-Url'] = apiGatewayUrls[bgTestingEnv];
  // }

  const version = packageJson.version;

  console.log("package version: ", version);

  headerOptions['stage'] = version;

  const [queryResult, queryAction] = useQuery(query, {
    ...{ ...queryOptions, url },
    headers: { ...queryOptions.headers, ...headerOptions },
    onCompleted: (result) => {
      if (queryOptions.onCompleted) {
        queryOptions.onCompleted(result);
      }

      if (result?.data?.missingPSXCodeTranslations?.length) {
        translationLogger({
          variables: {
            missedTranslations: result.data.missingPSXCodeTranslations,
          },
        });
      }
    },
    onError: (error) => {
      const gQLError = error?.response?.data?.errors?.[0]?.extensions;
      const regEx = /4\d{2}|5\d{2}/;
      if (
        regEx.test(gQLError?.status) &&
        gQLError?.pageType !== 'Custom' &&
        gQLError?.pageType !== 'Ignore' &&
        error?.operationName !== 'ProviderSearch'
      ) {
        const errorStoreValues = {
          message: gQLError?.message,
          code: gQLError?.status,
          source: error?.operationName,
          uiSection: linkName || originLinkNameForAnalytics,
        };
        setErrorStore(errorStoreValues);
        // @ts-ignore
        const urlParams = convertObjectToUrlParams(
          {},
          {
            ...errorStoreValues,
          }
        );

        navigate(
          `${
            errorCodeRouteMap[gQLError?.status] || errorCodeRouteMap['4xx']
          }${urlParams}`
        );
      }
      if (queryOptions.onError && typeof queryOptions.onError === 'function') {
        queryOptions.onError(gQLError);
      }
    },
  });
  return [queryResult, queryAction];
};
